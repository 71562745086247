'use client';
import AppStorage from '@/utils/helpers';

// Error boundaries must be Client Components

export default function GlobalError({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  return (
    // global-error must include html and body tags
    <html>
      <body>
        <div className="min-h-screen flex flex-col justify-center items-center bg-gray-200 px-4">
          <div className="max-w-md w-full bg-white shadow-md rounded-lg p-8 text-center">
            <h2 className="text-2xl font-bold text-red-600 mb-4">
              Sorry, we couldn&apos;t complete that action!
            </h2>
            {error.digest && (
              <p className="text-gray-600 mb-4">Error Digest: {error.digest}</p>
            )}
            <div className="flex gap-3 justify-center w-full">
              <button
                onClick={() => reset()}
                className="bg-primary-60 text-white px-4 py-2 rounded hover:bg-primary-400 transition-colors"
              >
                Try again
              </button>
              <button
                onClick={() => {
                  window.location.href = '/';
                  AppStorage.clear();
                }}
                className="bg-primary-60 text-white px-4 py-2 rounded hover:bg-primary-400 transition-colors"
              >
                Go to home
              </button>
            </div>
          </div>
        </div>
      </body>
    </html>
  );
}
